<template>
  <div class="main">
    <div class="storeTop">
      <div style="position: absolute;left:0px;font-size: 24px;color: #00d7fe;padding-left: 5px;cursor: pointer" @click="back()"><img src="../assets/store/back.png"/> 返回</div>
<div>一店一档</div>
    </div>
    <div class="storeBody">
      <div class="leftBox rtv">
        <div class="leftBoxOne">
          <div class="title">今日风险预警</div>
        <div class="abs" style="right: 25px;top:14px;cursor: pointer" @click="$refs.childDialog.openWarning('', 1,{id:id,name:name})"><img src="../assets/store/1.png"/> </div>

        <div class="leftBox1">
                 {{storeMessageList.total}}
        </div>
        </div>
        <div class="leftBoxTwo">
          <div class="title" style="">风险数据统计</div>
          <div class="left2-sj">2020-01-01 00:00:00 至 {{nowTime}}</div>
          <div class="left2-2">
            <div class="left2-2box">
              <div class="item1  c1">{{zgcs.zzzg}}次</div>
              <div class="item3 ">自主整改数</div>
            </div>
            <div class="left2-2box">
              <div class="item1  c2">{{zgcs.zlzg}}次</div>
              <div class="item3 ">责令整改数</div>
            </div>
            <div class="left2-2box">
              <div class="item1  c3">{{zgcs.jccz}}次</div>
              <div class="item3 ">检查处置数</div>
            </div>
            <div class="left2-2box">
              <div class="item1  c4">0次</div>
              <div class="item3 ">联动处置数</div>
            </div>
            <div class="left2-2box">
              <div class="item1  c5">0次</div>
              <div class="item3 ">未处置</div>
            </div>
            <div class="left2-2box">
               <div class="item1  c6" >{{kfNum}}次</div>
              <div class="item3 ">扣分次数</div>
            </div>
          </div>
        </div>
        <div class="leftBoxThree rtv">
          <div class="title" style="">药店动态</div>
          <div class="leftBoxThree2" style="margin-top: 24px">
            <div class="item" >
              <div class="item1" style="">{{dynamic.form}}人</div>
              <div class="item2" style="">自查次数</div>
            </div>
            <div class="item" >
              <div class="item1" style="">{{dynamic.commitment}}人</div>
              <div class="item2" style="">承诺次数</div>
            </div>
            <div class="item">
              <div class="item1" style="">{{dynamic.training}}人</div>
              <div class="item2" style="">培训次数</div>
            </div>
<!--            <div class="item" @click="$refs.childDialog.openJrfks(store.id)">
              <div class="item1" style="">{{visitorData.total}}人</div>
              <div class="item2" style="">自查次数</div>
            </div>
            <div class="item" @click="$refs.childDialog.openFyyc(store.id)">
              <div class="item1" style="">{{visitorData2.total}}人</div>
              <div class="item2" style="">承诺次数</div>
            </div>
            <div class="item" @click="$refs.childDialog.openCyryyc(store.id)">
              <div class="item1" style="">{{storePersonHs}}人</div>
              <div class="item2" style="">培训次数</div>
            </div>-->
          </div>
        </div>
        <div class="leftBoxFour flex">
<div class="leftBoxFour1" style="margin-right: 9px">
  <div class="title" style="">当前加分申请</div>
<div style="width: 245px;margin: 0 auto">
  <div class="auditInput">
    <el-input
        :disabled="true"
        type="textarea"
        :rows="2"
        placeholder="暂无申请"
        v-model="jiafen">
    </el-input>
  </div>

  <div class="auditBox">
<div class="btnAudit" @click="openAddAudit()"><div style="padding-top: 5px;padding-right: 15px">加分审核</div></div>
  </div>
</div>
</div>
<div class="leftBoxFour2 rtv">
  <div class="title" style="padding-top: 18px">今日处方流转数</div>
<div class="leftBoxFour2-1" @click="$refs.childDialog.openPrescription('处方流转',1,false,store.id)">
<div style="padding-top: 50px">{{prescriptionData.total}}</div>
</div>
</div>
        </div>
      </div>
      <div class="centerBox">
        <div class="centerBox1 rtv">
          <div class="score abs hand" @click="openKoufen()">
            <span :style="store.level=='D'?'color: #E82A02':''">{{store.score||100}}分</span>
          </div>
          <div class="score2 abs">当前药店评级</div>
          <div style="height: 21px"></div>
          <div class="centerBox1-2">
            <div style="float: right" class="centerBox1-2-1">
              <span style="color: #00a418" v-if="store.score>=80&&store.score<=100">{{store.level}}级</span>
              <span style="color: #f7ff29" v-else-if="store.score>=60&&store.score<=79">{{store.level}}级</span>
              <span style="color: #E82A02" v-else-if="store.score>=0&&store.score<=59">{{store.level}}级</span>
              <span style="color: #00a418" v-else>A级</span>
            </div>
            <div style="padding-top: 5px">{{store.name}}</div>
          </div>
<div class="store">

  <div class="store1" style="height: 29px"></div>
  <div class="store2 overflow" style="width: 421px;" :title="store.address">地址：{{store.address}}</div>
  <div class="store2">负责人： <span class="c1" style="margin-right: 55px">{{store.fzr}}</span></div>
  <div class="store2">店长： {{store.contact}}<span class="c1" style="margin-left:10px">{{ store.phone }}</span></div>
</div>
        </div>
        <div class="centerBox2 rtv" style="margin-top: 15px">
          <div id="video" style="padding: 15px">
            <iframe
                id="ship"
                :src="ship"
                width="100%"
                height="480"
                allowfullscreen
                v-if="videoType==1"
            />
            <div v-show="videoType==2">
              <div v-show="i==tongdao" v-for="(x,i) in video">
                <video
                    :id="'myVideo' + i"
                    class="video-js vjs-default-skin vjs-big-play-centered"
                     controls
                    preload="auto"
                    style="width: 100%;height: 480px"
                />
              </div>
            </div>
          </div>
          <div class="wsd">
            <div class="flex-v">
             <img src="../assets/store/wen.png" width="15" height="20" style="margin-right: 5px"/>
              <div class="bai" style="font-size: 16px;margin-right: 5px">存储温度</div>
              <div class="c1 wsdNum">{{wsdData.tem}}℃</div>
            </div>
            <div class="flex-v">
             <img src="../assets/store/shi.png" width="15" height="20" style="margin-right: 5px"/>
              <div  class="bai" style="margin-right: 5px">存储湿度</div>
              <div class="c1 wsdNum">{{wsdData.hum}}%</div>
            </div>
          </div>
<!--<div style="padding: 15px">-->
<!--  <img src="../assets/store/no.jpg" width="100%"/>-->
<!--</div>-->
        </div>
        <div class="centerBox4 rtv">
          <div class="title" style="">药店上报信息</div>
          <div id="sbxx">
            <table width="100%" cellpadding="0" cellspacing="0">
              <tr class="biaoTitle">
                <td>序号</td>
                <td>上报内容</td>
                <td>上报时间</td>
                <td>上报人</td>
                <td>操作</td>
              </tr>
              <tr class="biaoContent" v-for="(x,i) in feedbackData.list">
                <td>{{i+1}}</td>
                <td><div class="overflow">{{x.content}}</div></td>
                <td>{{x.updateDate}}</td>
                <td>{{x.memberName}}</td>
                <td><div class="btn" @click="$refs.childDialog.openShangbao(x)">查看</div></td>
              </tr>

            </table>
          </div>
        </div>
      </div>
      <div class="rightBox">
        <div class="rightBox1 rtv">
          <div class="titleRight flex-v" @click="$refs.childDialog.openinquire(store)">
            <div style="margin-right: 10px">进销存查询</div>
            <div><img src="../assets/store/1.png"/></div>
          </div>
          <div class="title">药品进销存情况</div>
          <div style="height: 16px"></div>
          <div class="rightBox1_2">
<div class="rightBox1_2_c">
  <div style="height: 12px"></div>
  <div class="item">
    <span class="item1 bai">当前购进总量</span> <span  class="item2 c1">{{total.purchase || 0}}</span>
  </div>
  <div class="item">
    <span class="item1 bai">当前销售总量</span> <span  class="item2 c1">线上：<span class="shuzi" style="margin-right: 20px">2055</span> 线下：<span class="shuzi">{{total.sale}}</span></span>
  </div>
  <div class="item">
    <span class="item1 bai">当前库存总量</span> <span  class="item2 c1">{{total.stockTotal}}</span>
  </div>
</div>
            <div class="rtv" style="margin-top: 20px">
              <div class="abs" style="top:1px;left:201px">
                <div id="stock" style="width: 300px;height: 140px" ></div>
                <div class="bai" style="text-align: center;width: 180px">库存药品占比分析</div>
              </div>
              <div>
                <div id="Unsalable" style="width: 180px;height: 140px" ></div>
                <div class="bai" style="text-align: center;width: 180px">滞销药品占比</div>
              </div>

            </div>
          </div>
        </div>
        <div class="rightBox2 rtv">
          <div class="titleRight flex-v" @click="$refs.childDialog.dialogDandian=true">
            <div style="margin-right: 10px">单店管控调整</div>
            <div @click="$refs.childDialog.dialogDandian=true"><img src="../assets/store/1.png"/></div>
          </div>
          <div class="title">管控药品存量</div>
<!--          <div class="dateRange">
            <div class="item" :class="{active:controlZt==1}" @click="changeControl(1)">今日</div>
            <div class="item" :class="{active:controlZt==2}" @click="changeControl(2)">本周</div>
            <div class="item" :class="{active:controlZt==3}" @click="changeControl(3)">本月</div>
            <div class="item" :class="{active:controlZt==4}" @click="changeControl(4)">本年</div>
          </div>-->
          <div style="height: 53px"></div>
          <div id="control" style="width: 555px;height: 190px" ></div>
          <div style="height: 20px"></div>
          <div class="controlNosale">
           <span style="float: right" class="c1 controlNosale-2">12.43%</span> <span class="bai" style="font-size: 20px">本店禁售药品占比：</span>
          </div>
        </div>
        <div class="rightBox3">
          <div class="title">药店证照管理</div>
<div class="zzgl">
  <div class="item" @click="$refs.childDialog.openJyxkz(store)">
    <div class="box">
      <div class="box_c">
        <div class="item2"><span class="bai">证件数量：</span><span class="c1">{{jyxkzData.length}}</span></div>
        <div><span class="bai">当前状态：</span><span class="c2">正常</span></div>
      </div>
    </div>
    <div class="box2">药店经营许可证</div>
  </div>
  <div class="item" @click="$refs.childDialog.openYsxkz(store)">
    <div class="box">
      <div class="box_c">
        <div class="item2"><span class="bai">证件数量：</span><span class="c1">
<!--          {{picYs.length+picYszy.length}}-->
          {{picYszy.length}}
        </span></div>
        <div><span class="bai">当前状态：</span><span class="c2">正常</span></div>
      </div>
    </div>
    <div class="box2">执业药师/药师许可证</div>
  </div>
  <div class="item"  @click="$refs.childDialog.openJkz(store)">
    <div class="box">
      <div class="box_c">
        <div class="item2"><span class="bai">证件数量：</span><span class="c1">{{picJkz.total}}</span></div>
        <div><span class="bai">当前状态：</span><span class="c2">正常</span></div>
      </div>
    </div>
    <div class="box2">从业人员健康证</div>
  </div>
</div>
        </div>
      </div>
    </div>
    <yun-dialog ref="childDialog" />

  </div>

</template>

<script>
import axios from "axios";
import * as echarts from "echarts";
import {
  messagePage,
  storePhoto,
  storeControl,
  unqualified,
  yqypList,
  healthList,
  pharmacistImage,
  juniorImage,
  storeTotal,
  visitor,
  storeDetail,
  storeMessage,
  storeWsd,
  storeLevel,
  saleList,
  storeImage,
  feedback,
  disposalList,
  storePerson,
  certList, licenceList, monitor, storeDynamic, DeductionList
} from "@/api/yaojian"
import moment from "moment";
import jifen from "@/assets/data/jifen.json";
import 'video.js/dist/video-js.css'
import videojs from 'video.js';
import 'videojs-contrib-hls'
import yunDialog from "@/views/yunDialog";

export default {
  name: "smartStore",
  components:{yunDialog},
  data(){
    return {
      kfNum:0,
      storeId:0,
      dynamic:{},
      jyxkzData:[],
      zgcs:{zzzg:0,zlzg:0,jccz:0},
      jifen:jifen,
      playList: [],
      parentUrl:"",
      tongdao:0,
      nowTime: moment().format("yyyy-MM-DD HH:MM:ss"),
      today:[moment().format("yyyy-MM-DD 00:00:01"),moment().format("yyyy-MM-DD 23:59:59")],
      store:{},
      jiafen:"",
      videoType:1,
      id:1,
      name:"",
      video:[],
      ship:'',
      storeMessageList:[],
      prescriptionData:[],
      feedbackData:[],
      wsdData:{},
      disposalData:{},
      visitorData:{total:0},
      visitorData2:{total:0},
      total:{},
      storePersonHs:0,
      picYyzz:[],
      picYs:[],
      picYszy:[],
      picJkz:[],
      arrDate:[
        moment().format("yyyy-MM-DD 00:00:00"),
        moment().startOf('week').format("yyyy-MM-DD 00:00:00"),
        moment().startOf('month').format("yyyy-MM-DD 00:00:00"),
        moment().startOf('quarter').format("yyyy-MM-DD 00:00:00"),
        moment().startOf('year').format("yyyy-MM-DD 00:00:00")
      ],
      controlZt:1,
      controlData:{yqyp:0,cfy:0,xfj:0,tsyp:0,bhg:0},
    }
  },
  mounted() {
    this.init()
    this.getChart()
   this.getVideo(this.$route.query.id)
    },
  created() {
    this.id=this.$route.query.id
    this.storeId=this.$route.query.id
this.parentUrl=this.$route.query.url
    this.getZg()
    },
  methods:{
    async kfList(){
      let data={
        page:1,
        count:9999,
        storeId:this.storeId,
        zero:false
      }
      let rs=await DeductionList(data)
      this.kfNum=rs.data.list.filter(x=>x.score<0).length
    },
    openAddAudit(){
      this.$refs.childDialog.openAddAudit(this.storeId)
      },
    async getDynamic(){
     let rs=await storeDynamic({storeId:this.id})
     if(rs.code==200){
       this.dynamic=rs.data
     }
    },
    getZg(){
      //自主整改
      disposalList({page:1,count:5,storeId:this.id,status:40}).then(rs=>{
          this.zgcs.zzzg=rs.data.total
      })
      //责令整改
      disposalList({page:1,count:5,storeId:this.id,status:10}).then(rs=>{
        this.zgcs.zlzg=rs.data.total
      })
      //检查处置
      disposalList({page:1,count:5,storeId:this.id,status:25}).then(rs=>{
        this.zgcs.jccz=rs.data.total
      })
    },
    openKoufen(){
      this.$refs.childDialog.openKoufen(this.storeId,this.name,this.store.level)
    },
    back(){
      this.$router.push(this.parentUrl)
    },
    init(){//页面初始化
      this.name=this.$route.query.name
      this.getStoreDetail()
      this.getJyxkz()
      this.getStoreMessage()
      this.getStoreWsd()
      this.getStoreLevel()
      this.getPrescriptionList()
      this.getDisposalList()
      this.getStorePerson()
      this.getControl()
      this.getDynamic()
      this.kfList()
    },
    changeControl(t){
      this.controlZt=t
    },
    //库存占比
    getStock(){
      unqualified().then(rs=>{

      })
    },
    //管控药品销售记录
   getControl(){
     let startTime=this.arrDate[0]
     let endTime=this.today[1]
     // let post={
     //   page:1,
     //   count:10,
     //   storeId:this.id,
     //   endTime:endTime,
     //   startTime:startTime,
     // }

     storeControl({
       storeId:this.id,
       startTime:startTime,
       endTime:endTime
     }).then(rs=>{
       this.controlData.yqyp=rs.data.yiqing
       this.controlData.xfj=rs.data.xifenji
       this.controlData.tsyp=rs.data.teshu
       this.chartControl()
     })
   },

    //从业人员核酸
    getStorePerson(){
      let post={
startTime: moment().add(-7,'day').format("yyyy-MM-DD 00:00:00"),
endTime: this.today[1],
        storeId:this.id
      }
      storePerson(post).then(rs=>{
        this.storePersonHs=rs.data.size-rs.data.count
      })
    },
    //获取自主整改
    getDisposalList(){
      let post={page:1,count:1,storeId:this.id,status:40}
      disposalList(post).then(rs=>{
this.disposalData=rs.data
      })
    },
    getPrescriptionList(){
      let post={
        isPrescription:1,
        type:1,
        page:1,
        count:10,
        storeIds:this.id,
        startTime:moment().format("yyyy-MM-DD 00:00:00"),
        endTime:moment().format("yyyy-MM-DD 23:59:59"),
      }
      saleList(post).then(rs=>{
       this.prescriptionData=rs.data
      })
    },
    getStoreLevel(){
      storeLevel({storeId:this.id}).then(rs=>{
        this.store.level=rs.data.list[0].level
        this.store.score=rs.data.list[0].score
        console.log(this.store,"score")
      })
    },
    getStoreWsd(){
      storeWsd({storeId:this.id}).then(rs=>{
        this.wsdData=rs.data
      })
    },
    getJyxkz(){
      let store={id:this.id,name:this.name}
      let post={page:1,count:10,name:store.name}
      let post2={page:1,count:10,status:20,type:10,keyword:store.name}
      let post3={page:1,count:10,status:20,type:40,keyword:store.name}
      let post4={page:1,count:10,status:20,type:20,keyword:store.name}
      let post5={page:1,count:10,status:20,type:30,keyword:store.name}
      Promise.all([storePhoto(post),licenceList(post2),licenceList(post3),licenceList(post4),licenceList(post5)]).then(rs=>{
        //处理营业执照-------------------------start
        this.jyxkzData=rs[0].data.list
        this.jyxkzData.map(x=>{
          if(x.attr){
            let attr=JSON.parse(x.attr)
            x.validDate=attr.create_time
            x.legalName=attr.person_name
            x.address=attr.address
          }
          x.ctype="营业执照"
        })
        //处理营业执照-------------------------end
        //药品经营许可证-------------------------start
        let jyxkz=rs[1].data.list
        jyxkz.map(x=>{
          x.ctype="药品经营许可证"
          x.photo=x.image
        })
        this.jyxkzData= this.jyxkzData.concat(jyxkz);
        //药品经营许可证-------------------------end
        //医疗器械备案证-------------------------start
        let ylqxba=rs[2].data.list
        ylqxba.map(x=>{
          x.ctype="医疗器械备案证"
          x.photo=x.image
        })
        this.jyxkzData= this.jyxkzData.concat(ylqxba);
        //医疗器械备案证-------------------------end
        //食品经营许可证-------------------------start
        let food=rs[3].data.list
        food.map(x=>{
          x.ctype="食品经营许可证"
          x.photo=x.image
        })
        this.jyxkzData= this.jyxkzData.concat(food);
        //食品经营许可证-------------------------end
        //食品经营许可证-------------------------start
        let qxxkz=rs[4].data.list
        qxxkz.map(x=>{
          x.ctype="医疗器械许可证"
          x.photo=x.image
        })
        this.jyxkzData= this.jyxkzData.concat(qxxkz);
        //食品经营许可证-------------------------end
        console.log(this.jyxkzData)

      })
    },
    getStoreDetail(){
      let post={name:this.name}
      storeDetail(post).then(rs=>{
        this.store=rs.data
      })
      //营业执照列表
  /*    storePhoto({name:this.name,page:1,count:10}).then(rs=>{
        let arr=rs.data.list[0].attr
       let  attr=JSON.parse(arr)
        this.store.fzr=attr.person_name
      })*/
      //企业执照

      //药师执照
      juniorImage({storeId:this.id}).then(rs=>{
        this.picYs=rs.data
      })
      //执业药师
      certList({page:1,count:10,storeName:this.name}).then(rs=>{
        this.picYszy=rs.data.list
      })
      //健康证
      healthList({storeId:this.id}).then(rs=>{
        this.picJkz=rs.data
      })

      feedback({storeId:this.id,page:1,count:3,keyword:this.name}).then(rs=>{
        this.feedbackData=rs.data
      })
/*      visitor({
        page:1,
        storeId:this.id,
        startTime:this.today[0],
        endTime:this.today[1],
      }).then(rs=>{

        this.visitorData=rs.data
      })*/
/*      visitor({
        page:1,
        storeId:this.id,
        level:"黄码",
        startTime:this.today[0],
        endTime:this.today[1],
      }).then(rs=>{
        this.visitorData2=rs.data
      })*/
      storeTotal({storeId:this.id}).then(rs=>{
        this.total=rs.data
        this.total.purchase=parseInt(this.total.purchase)
        this.total.sale=parseInt(this.total.sale)
        this.total.stockTotal=parseInt(this.total.stockTotal)
      })
    },
    getStoreMessage(){
      let post={
        start:this.today[0],
        end:this.today[1],
        page:1,
        count:100,
        keyword:this.name
      }
      messagePage(post).then(rs=>{
        this.storeMessageList=rs.data
      })
    },
getChart(){
  this.chartUnsalable()
  this.chartStock()

},
    chartUnsalable(t){//滞销药品占比
      this.id=t
      let option = {
        title: {
          text:Math.round(Math.random()*5+5)+'%',
          x: 'center',
          y: 'center',
          textStyle: {
            fontWeight: 'normal',
            color: '#0277FA',
            fontSize: '30'
          }
        },
        color: ['rgba(176, 212, 251, .2)'],
        legend: {
          show: false,
          itemGap: 12,
          data: ['01', '02']
        },

        series: [{
          name: 'Line 1',
          type: 'pie',
          clockWise: true,
          radius: ['70%', '86%'],
          itemStyle: {
            normal: {
              label: {
                show: false
              },
              labelLine: {
                show: false
              }
            }
          },
          hoverAnimation: false,
          data: [{
            value:10,
            name: '01',
            itemStyle: {
              normal: {
                color: { // 完成的圆环的颜色
                  colorStops: [{
                    offset: 0,
                    color: '#00cefc' // 0% 处的颜色
                  }, {
                    offset: 1,
                    color: '#367bec' // 100% 处的颜色
                  }]
                },
                label: {
                  show: false
                },
                labelLine: {
                  show: false
                }
              }
            }
          }, {
            name: '02',
            value: 20
          }]
        }]
      }
      let myChart = echarts.init(document.getElementById("Unsalable"))
      myChart.setOption(option);
    },
    chartStock(){//库存药品占比分析
      let data=[
        { value: 91.3, name: '正常',color:"#C41DF3",lei:"value1" },
        { value: 8.4, name: '近效期(一个月)',color:"#F78306" ,lei:"value2"},
        { value: 0.3, name: '不合格',color:"#0096FF" ,lei:"value4"},
      ]
      let color=data.map(x=>{
        return x.color
      })
      let option = {
        tooltip: {
          trigger: 'item',
          formatter: '{b0}: {c0}%'
        },
        legend: {
          itemHeight: 12, //修改icon图形大小
          itemWidth: 12, //修改icon图形大小
          show: true,
          top: '10%',
          left: '50%',
          textStyle: {
            rich:{
              title:{
                display:"block",
                width:80,
                height:28,
                lineHeight:15,
                margin:10,
                fontSize:"16px",
                color: '#fff',
              },
              value1:{
                fontSize:"16px",
                color: color[0],
              }
              ,
              value2:{
                fontSize:"16px",
                color: color[1],
              },
              value3:{
                fontSize:"16px",
                color: color[2],
              },
              value4:{
                fontSize:"16px",
                color: color[2],
              },
              value5:{
                fontSize:"16px",
                color: color[4],
              }
            }
          },
          itemGap: 5,
          formatter: (name) => {

            let arr=data.filter(x=>x.name==name)
            if(name && name.length > 3){
            var str1 = name.substring(0, 3)
            var str2 = name.substring(3)
            name = str1 + "\n" + str2
        }


            return `{title|${name}}{${arr[0].lei}|${arr[0].value}%}`;
          },
        },
        series: [
          {
            name: '库存药品占比分析',
            type: 'pie',
            center:['25%', '50%'],
            radius: ['50%', '86%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: false,
                fontSize: '40',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: data,
            color:color
          }
        ]
      };
      let myChart = echarts.init(document.getElementById("stock"))
      myChart.setOption(option);
    },
    chartControl(){//管控药品销售情况
     // let data=[this.controlData.yqyp, 200, this.controlData.xfj, this.controlData.tsyp, 70]
      let data=[80, 90, 0]
      let option = {
        grid:{
          top:"1%",
          right:'1%',
          bottom:'10%',

        },
        color:["#0278F9"],
        xAxis: {
          axisLabel: {
            show: true,
            color:'#fff'
          },
          type: 'category',
          axisLine: {
            show: true,//显示坐标轴线
          },
          data: ['含兴奋剂药品', '含麻黄碱药品', '不合格药品']
        },
        tooltip: {},//加了这个空就能显示鼠标移上去提示
        yAxis: {
          splitLine:false,
          type: 'value'
        },
        series: [
          {
            data: data,
            type: 'bar',
            barWidth:30
          }
        ]
      };
      let myChart = echarts.init(document.getElementById("control"))
      myChart.setOption(option);
      // if(t==1){
      //   option.series[0].data[0]=240
      //   myChart.setOption(option,true);
      // }else{
      //   myChart.setOption(option);
      // }
    },
    //监控开始9999999----------start
    loadPlay() {
      let index = 0
      this.video.forEach(item => {
        if (item.type === 2) {
          var newUrl = item.url
          const myPlayer = videojs('myVideo' + index, {
            bigPlayButton: true, // 是否显示播放按钮（这个播放按钮默认会再左上方，需用CSS设置居中）
            textTrackDisplay: true,
            posterImage: true,
            errorDisplay: true,
            controlBar: true,
            autoplay: 'muted', // 这个说是自动播放
            controls: true,
            width: '800px',
            height: '400px'
          }, function() {
            this.play()
          })
          this.playList.push(myPlayer)
          myPlayer.src(newUrl)
          myPlayer.load(newUrl)
        }
        index++
      })
    },
    async getVideo(id){
      //获取视频---
      let that=this
      let rs=await monitor({id:id})
      if(rs.code==200){
        that.video=rs.data
        if(that.video.length>0&&that.video[0].type==2){
          that.videoType=2
          setTimeout(() => {
            that.loadPlay()
          }, 1000)
        }
        if(that.video.length>0){
          that.ship='https://open.ys7.com/ezopen/h5/iframe?url=' + that.video[0].url + '&autoplay=1&accessToken=' + that.video[0].accessToken + '&begin=' + that.video[0].begin + '&end' + that.video[0].end+''
        }
        let obj={}
        for(let i in that.video){
          let j=i++
          let aa="通道"+j
          obj={value:i,label:aa}
        }
      }
      //获取视频---
    },
    //监控开始--------------------end
  }
}
</script>

<style scoped>
.overflow {
  width: 200px;
  word-break: break-all;

  text-overflow: ellipsis;

  display: -webkit-box;

  -webkit-box-orient: vertical;

  -webkit-line-clamp: 1; /* 这里是超出几行省略 */

  overflow: hidden;
}
.hand{cursor: pointer}
.shuzi{font-size: 25px;font-weight: bolder}
.dateRange .item{width:51px;
text-align: center;
color: #00d7fe;
border-right: 1px solid #0277FA;
  cursor: pointer;
  line-height: 24px;
}
.dateRange .active{color: #FFFFFF;
background-color: #0277FA}
.dateRange{
  border-radius: 5px;
  display: flex;
  height: 24px;
  width: 204px;
  border: 1px solid #0277FA;
  position: absolute;
  right: 10px;
  top:50px
}
.controlNosale{
  margin: 0 auto;
  margin-top: 17px;
  /*background-image: url("../assets/store/right2-2.png");*/
  width: 530px;
  height: 39px;
  line-height: 31px;
}
.controlNosale-2{
  font-size: 36px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #FBC902;
}
.score{
  width:80px;
  height: 55px;
  font-size: 26px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #FBC902;
  top: 97px;
  left: 509px;
}
.score2{
  top: 179px;
  left: 482px;
  width: 1408px;
  height: 20px;
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #00d7fe;
}
.centerBox1-2-1{
  font-size: 30px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #FBC902;
}
.centerBox1-2{
  font-size: 24px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #00d7fe;
}
.centerBox1-2{
padding: 0 25px;
}
.zzgl .box_c{
  padding-left: 15px;
}
.zzgl{display: flex;justify-content: space-between;padding: 25px}
.zzgl .box{
  flex-direction: column;
  display: flex;
  justify-content: center;
  background-image: url("../assets/store/right3-2.png");
  width: 171px;
  height: 85px;
}
.zzgl .box2{
  text-align: center;
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #00d7fe;
}
.rightBox2{
  margin-top: 15px;
  background-image: url("../assets/store/right2.png");
  width: 590px;
  height: 377px;
}
.rightBox3{
  margin-top: 15px;
  background-image: url("../assets/store/right3.png");
  width: 590px;
  height: 190px;
}
.rightBox1_2_c .item2{font-size: 20px;margin-left: 10px}
.rightBox1_2_c .item .item1{font-size: 18px;line-height: 36px}
.rightBox1_2_c .item{
  padding-left: 17px;
  margin-bottom: 3px;
  width: 503px;
  height: 36px;
  margin: 0 auto;
  background: rgba(2,120,249,.2);
  border: 1px solid rgba(2,120,249,.2);
  border-radius: 4px;
}
.rightBox1_2{
  margin: 0 auto;
  width: 524px;
  height: 138px;
  background: rgba(2, 120, 249, 0);
  border: 1px solid #0278F9;
  border-radius: 4px;
}
.titleRight{
  line-height: 20px;
  color: #00d7fe;
  position: absolute;
  right: 20px;
  top:20px
}
.rightBox{margin-left: 15px}
.rightBox1{
  background-image: url("../assets/store/right1.png");
  width: 590px;
  height: 387px;
}
.biaoContent:nth-child(odd){
  background-color:rgba(0,150,255,.26);
}
#sbxx td{text-align: center;line-height: 34px;font-size: 18px;}
#sbxx{
  color: #EEEEEE;
  padding: 10px;
}
#sbxx .btn{
  cursor: pointer;
  margin: 0 auto;
  line-height: 25px;
  text-align: center;
  width: 59px;
  height: 25px;
  background: #0278F9;
  border-radius: 4px;
}
.biaoTitle{

  background-color: rgba(2,119,250,.3);
  font-weight: 500;}
.centerBox4{
  margin-top: 15px;
  background-image: url("../assets/store/center3.png");
  width: 670px;
  height: 223px;
}
.centerBox3-1{
  top: 14px;
  right: 28px;
  font-size: 36px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #FBC902;
}
.flex-v{display: flex;align-items: center}
.wsdNum{
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #FBC902;
}
.wsd{
  padding: 5px 10px;
  line-height: 36px;
  bottom: 20px;
  left:20px;
  height: 73px;
  background: rgba(3,22,55,.6);
  position: absolute;
}
.centerBox2{
  background-image: url('../assets/store/center2.png');
  width: 670px;
  height: 510px;
}
.centerBox3{
  background-image: url('../assets/store/center3.png');
  width: 670px;
  height: 77px;
  margin-top: 15px;
}
.store{padding-left: 22px}
.store2{line-height: 200%;color: #FFFFFF}
.store1{
  line-height: 200%;
  font-size: 24px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #00d7fe;}
.centerBox{margin-left: 15px}
.centerBox1{
  background-image: url('../assets/store/center1.png');
  background-size: 100% 100%;
  width: 670px;
  height: 221px;
}

.auditBox{
  /*background-image: url('../assets/store/diandian.png');*/
  height: 31px;width: 100%;
  background-repeat: no-repeat;
  background-position: 10px 10px;
}
.auditInput {margin-top: 15px;margin-bottom: 15px}
.auditInput >>>.el-textarea__inner{
  width: 245px;
  height: 115px;
  background: rgba(2, 120, 249, 0.1);
  border: 1px solid #0278F9;
  color: #fff;
  font-size: 18px;
}
.btnAudit{
  cursor: pointer;
  float: right;
  background-image: url("../assets/store/btn.png");
  width: 108px;
  height: 31px;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FBC902;
  text-align: center;
}
.leftBoxThree2 .item1{
  margin-top: 9px;
  font-size: 30px;
  font-weight: bold;
  color: #FBC902;
}
.leftBoxThree2 .item2{
  margin-top: 75px;
  font-size: 18px;
  font-weight: bold;
  color: #EEEEEE;
}
.leftBoxThree2{
  display: flex;
  justify-content: space-between;
  height: 150px;width:500px;margin: 0 auto}
.leftBoxThree2 .item{
  text-align: center;
  width: 150px;
  height: 181px;
background-image: url("../assets/store/left3-1.png");
  background-position: center 10px;
  background-repeat: no-repeat;
}
.pjScore{
  font-size: 24px;
  color: #FBC902;
  right: 23px;
  top:53px
}
.leftBoxFour2-1{
  background-image: url("../assets/store/left4-2.png");
  width: 180px;
  height: 138px;
  margin: 0 auto;
  margin-top: 41px;
  text-align: center;
  font-size: 30px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #FBC902;

}
.flex{display: flex}
.leftBoxFour1{
  background-image: url("../assets/store/left4.png");
  width: 287px;
  height: 252px;
}
.leftBoxFour2{
  background-image: url("../assets/store/left4-3.png");
  width: 293px;
  height: 252px;
}
.leftBoxFour{margin-top: 16px}
.bai{color: #FFFFFF}
.c1{color:#FBC902}
.c2{color: #1DCE3A}
.c3{color: #0278F9}
.c4{color: #E82A02}
.c5{color: #00D7FE}
.c6{color: #C41DF3}
.left2-2box{
  width: 175px;height:105px;overflow: hidden;
  display: flex;
flex-direction: column;
  justify-content: center;
  text-align: center;
}
.left2-2box .item1{
  width: 100%;
  height: 34px;
  font-size: 36px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  line-height: 26px;

}
.left2-2box .item2{
  width: 100%;
  height: 14px;
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 26px;
  left: 119px;
  top:32px
}
.left2-2box .item3{
  width: 100%;
  height: 18px;
  font-size: 18px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #EEEEEE;
  line-height: 26px;

}
.left2-2{
  display: flex;
  flex-wrap: wrap;
  padding-left: 31px;
  padding-top: 19px;
  width:525px ;
  height: 211px;
}
.left2-sj{
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #EEEEEE;
  margin-top: 18px;
  margin-left: 30px;
}
.title{
  font-size: 28px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #00d7fe;
  padding-top: 9px;
  padding-left: 19px;
}
.rtv{position: relative}
.abs{position: absolute}
.leftBox1{
  position: absolute;
  background-position: 0px 40px;
  width: 93px;
  height: 109px;
  background-repeat: no-repeat;
  left: 352px;
  top:0px;
  color: #FBC902;
  font-size: 50px;
  font-family: FZLTZHK;
  font-weight: normal;
  text-align: center;
  padding-top: 20px;
}
.leftBoxOne{
  background-image: url("../assets/store/left1.png");
  width: 590px;
  height: 109px;

}
.leftBoxTwo{
  margin-top: 16px;
  background-image: url("../assets/store/left2.png");
  width: 590px;
  height: 336px;

}
.leftBoxThree{
  margin-top: 16px;
  background-image: url("../assets/store/left3.png");
  width: 590px;
  height: 242px;

}
.leftBox{ margin-left: 21px;}
.storeTop{
  background-image: url("../assets/store/top.png");
  height: 76px;
  line-height:76px;
  width: 1920px;
  text-align: center;
  font-size: 30px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #FFFFFF;

}
.main{
  width: 1920px;height: 1080px;overflow: hidden;
  background-image: url("../assets/store/bg.jpg");
}

.storeBody{display: flex}
</style>
